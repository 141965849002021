<template>
  <div class="formBox">
    <el-row class="pur-top">
      <el-col :span="3"
        ><span class="pur-size">{{ purTitle }}</span></el-col
      >
    </el-row>
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-form-item label="付款账号" prop="paymentAccount">
        <el-input v-model="form.paymentAccount"></el-input>
      </el-form-item>
      <el-form-item label="付款银行" prop="paymentBank">
        <el-input v-model="form.paymentBank" disabled></el-input>
      </el-form-item>
      <el-form-item label="联行号" prop="interbankNum">
        <el-input v-model="form.interbankNum"></el-input>
      </el-form-item>
      <!-- <el-form-item>
        <el-button type="primary" @click="onSubmit">保存</el-button>
      </el-form-item> -->
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        paymentAccount: "",
        paymentBank: "",
        interbankNum: "",
      },
      rules: {
        paymentAccount: [
          { required: true, message: "请输入付款账号", trigger: "change" },
        ],
        paymentBank: [
          { required: true, message: "请输入付款账号", trigger: "change" },
        ],
        interbankNum: [
          { required: true, message: "请输入付款账号", trigger: "change" },
        ],
      },
      purTitle: "", // 标题
    };
  },
  methods: {
    onSubmit() {
      console.log("保存");
    },
  },
  created() {
    this.purTitle = this.$route.meta.title;

    this.api.chainconfig
      .all({
        searchStr: "",
        companyId: sessionStorage.getItem("companyId"),
      })
      .then((res) => {
        if (res.data.code == 200) {
          this.form.paymentAccount = res.data.data[0].acctNum;
          this.form.interbankNum = res.data.data[0].cnapsCode;
          switch (res.data.data[0].bnkId) {
            case "01000000":
              this.form.paymentBank = "邮储银行";
              break;
            case "01020000":
              this.form.paymentBank = "工商银行";
              break;
            case "01030000":
              this.form.paymentBank = "农业银行";
              break;
            case "01040000":
              this.form.paymentBank = "中国银行";
              break;
            case "01050000":
              this.form.paymentBank = "建设银行";
              break;
            case "03010000":
              this.form.paymentBank = "交通银行";
              break;
            case "03020000":
              this.form.paymentBank = "中信银行";
              break;
            case "03030000":
              this.form.paymentBank = "光大银行";
              break;
            case "03040000":
              this.form.paymentBank = "华夏银行";
              break;
            case "03050000":
              this.form.paymentBank = "民生银行";
              break;
            case "03060000":
              this.form.paymentBank = "广发银行";
              break;
            case "03080000":
              this.form.paymentBank = "招商银行";
              break;
            case "03090000":
              this.form.paymentBank = "兴业银行";
              break;
            case "03100000":
              this.form.paymentBank = "浦发银行";
              break;
            case "04012900":
              this.form.paymentBank = "上海银行";
              break;
            case "04031000":
              this.form.paymentBank = "北京银行";
              break;
            case "04105840":
              this.form.paymentBank = "平安银行";
              break;
            case "00001014":
              this.form.paymentBank = "农村商业银行";
              break;
            case "00001015":
              this.form.paymentBank = "农村合作银行";
              break;
            case "00001016":
              this.form.paymentBank = "村镇银行";
              break;
            case "00001017":
              this.form.paymentBank = "民营银行";
              break;
            case "00001018":
              this.form.paymentBank = "城市信用社";
              break;
            case "00001019":
              this.form.paymentBank = "农村信用社";
              break;
            case "11111111":
              this.form.paymentBank = "统一银行";
              break;
            default:
              console.log("常规日期");
          }
        }
      });
  },
};
</script>

<style lang="less" scoped>
.formBox {
  height: 100%;

  /deep/ .el-form {
    height: 100% !important;
    padding: 33px 0 33px 33px;
    margin: 8px;
    background-color: #fff;
  }

  /deep/ .el-input__inner {
    width: 200px;
    height: 28px;
    margin-left: 32px;
  }

  /deep/ .el-button--primary {
    width: 86px;
    height: 36px;
    margin-left: 146px;
  }
}
// 头部开始
.pur-top {
  width: 100%;
  height: 50px;
  padding: 9px 24px 9px 32px;
  background-color: #fff;
  box-sizing: border-box;
  box-shadow: 2px 2px 10px rgba(102, 102, 102, 0.15);
  border: 1px solid rgba(102, 102, 102, 0.15);
  .pur-size {
    font-size: 16px;
    line-height: 32px;
    color: #333;
    font-weight: 600;
  }
  .pur-right {
    .el-button {
      margin-left: 16px;
      float: right;
    }
  }
}
// 头部结束
</style>